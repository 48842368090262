*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Red Hat Display';
}

input:-webkit-autofill {
      background-color: #fafafa;
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

input:autofill {
      box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-ekwfbp-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}

.sidebar-text > span {
  font-weight: 700 !important;
  color: #333333;
}