.wrap{
  width: 100%;
  background-color: #ffffff;
  padding: 20px 0;
  position: fixed;
  top:0;
  z-index: 5;
}

.wrapper-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  max-width: 1920px;
}
.topnav {
  overflow: hidden;
  background-color: transparent;
  z-index: 2;
  display: flex;
align-items: center;
justify-content: space-between;
width: 90%;
margin: 0 auto;
}

.topnav a {
  float: right;
  display: block;
  color: #000000;
  text-align: center;
  padding: 18px 16px;
  text-decoration: none;
  font-size: 32px;
}

.topnav a:hover {
  background-color: #31EAF4;
  color: black;
}

.topnav a.active {
  background-color: inherit;
  color: black;
}
.navlist {
   display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
  gap:30px;
  width: 90%;
}

.icon {
  display: none;
}

@media screen and (max-width: 881px) {
  .icon {
    display: block;
    position: absolute;
    top: 30px;
    right: 20px;
  }
  .hidden {
    display: none;
  }
  .show {
    display: block;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .navlist {
    gap: 20px;
    align-items: flex-start;
    width: 100%;
  }
  .wrapper-nav {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 600px) {
 


 
  .login-button{
    margin-top: 20px;
  }
}
  /* .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
    font-size: 32px;
    
  }
  .about-us {
    background-color: #31EAF4;
    color: #000000;
    font-size: 18px !important;
  } */

/* } */