.main-body {
    width: 100%;
    color: #000000;
    background-color: #ffffff;
    height: 100%;
    overflow-y: scroll;
  }
  
  .about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4%;
  }
  .about-image img {
    aspect-ratio: 9/7;
    object-fit: cover;
    border-radius: 8px;
  }
  .page-opener {
    background-color: blanchedalmond;
    padding: 150px 0px;
    width: 100%;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-us-text {
    margin-top: 100px;
    margin-bottom: 0px;
    color: #001c34;
    font-size: 66px;
    line-height: 76px;
    font-weight: 600;
  }
  
  .about-image {
    width: 48%;
    border-radius: 14px;
  }
  
  .first-text {
    margin: 0px auto;
    color: #4b4b4b;
    font-size: 20px;
    line-height: 30px;
    max-width: 1000px;
  }
  
  .main-about {
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .sub-header {
    font-family: "Poppins";
    margin: 0px auto 20px;
    color: #000;
    font-size: 48px;
    line-height: 58px;
    font-weight: 600;
    max-width: 1000px;
  }
  
  .about-text {
    font-size: 1.5vw;
    margin: 0;
    width: 48%;
    text-align: left;
    font-weight: 300;
    font-family: "Poppins";
  }
  .about-text > p {
    line-height: 58px;
  }
  .about-text:last-child {
    margin-bottom: 40px;
  }
  
  .mission {
    max-width: 1440px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    height: 700px;
    gap: 200px;
  }
  
  .mission-image {
    width: 60%;
    /* max-width: 450px; */
    height: 100%;
    position: relative;
  }
  
  /* .mission-image img {
      object-fit: cover;
      width: 100%;
      height: 100%;
  } */
  
  .hide-image {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0px;
    top: 50px;
  }
  .hide-image2 {
    position: absolute;
    width: 200px;
    height: 200px;
    right: 0px;
    bottom: 50px;
  }
  
  .main-image {
    max-width: 600px;
  }
  
  .mission-info {
    width: 40%;
    padding: 50px;
    margin-right: 50px;
    height: 100%;
    text-align: left;
  }
  
  @media screen and (max-width: 1024px) {
    .main-about {
      gap: 80px;
    }
    .first-text {
      line-height: 36px;
    }
  
    .about-text > p {
      line-height: 36px;
    }
    .about-image {
      width: 50%;
      margin: auto;
      height: 45vh;
    }
    .mission {
      flex-direction: column;
      height: 100%;
      gap: 0px;
      /* height: 500px; */
    }
    .mission-image {
      width: 100%;
    }
    .mission-info {
      width: 100%;
    }
   
  }
  
  @media screen and (max-width: 600px) {
    .main-body {
      height: 100%;
      overflow-y: scroll;
    }
    .page-opener{
      margin-bottom: 50px;
    }
    .main-about {
      gap: 20px;
  
      /* padding: 10px 0 20vh 0; */
    }
    .main-home {
      width: 90%;
    }
    .first-text {
      font-size: 16px;
      line-height: 26px;
    }
    .more {
      padding: 0px 10px;
    }
    .sub-header {
      font-size: 24px;
      line-height: 28px;
      margin: 20px 0px;
    }
    .about-text {
      font-size: 4vw;
    }
    .about-content {
      gap: 30px;
    }
    .about-content:nth-child(odd) {
      flex-direction: column-reverse;
    }
    .about-content:nth-child(even) {
      flex-direction: column;
    }
    .about-image {
      width: 90%;
      margin: auto;
      height: 40vh;
    }
    .about-text {
      width: 90%;
      margin: auto;
    }
    .mission {
      flex-direction: column;
      height: 100%;
      gap: 20px;
      padding: 20px;
    }
    .mission-image {
      width: 100%;
    }
    .hide-image {
      display: none;
    }
    .hide-image2 {
      display: none;
    }
    .main-image{
      max-width: 100%;
      width: 100%;
    }
    .mission-info {
      opacity: 0.9;
      width: 100%;
      padding: 20px 0px;
      margin-right: 0;
    }
    .reverse {
      flex-direction: column-reverse;
    }
  }
  @media only screen and (min-device-width: 700px) and (max-device-width: 1020px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .about-image {
      width: 50%;
      margin: auto;
      height: 25vh;
    }
  }
  