.wrapper{
  background-color:#1c1d21;
  height: 100%;
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.text {
  width: 75%;
  line-height: 2vw;
  word-spacing: 5px;
  font-family: 'Poppins';
  margin: 0;
}

.item {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.title{
  font-family: 'Poppins';
  font-weight: 500;
  margin: 0;
}
.copyright{ text-align: center;
  font-size: 14px;
  margin-top: 50px;}

.contact-info{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1920px;
  margin: auto;
}
@media screen and (max-width: 1024px) { 
  .text {
    font-size: 1.5vw;
}

}

@media screen and (max-width: 600px) { 
.wrapper{
  height: 100%;
  margin-top: 0px;
  flex-direction: column;
}
.contact-info{
flex-direction: column;
 justify-content:flex-start;
 gap: 15px;
}
.item {
width: 90%;
margin: auto;
text-align: center;
}
.title{
  font-weight: 400;
  padding: 0;
}
 .text {
    font-size: 3.5vw;
     line-height: 20px;
}
}