.App {
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type="text"],
input[type="tel"],
input[type="number"],
input[type="textarea"],
input[type="email"]
textarea {
  font-size: 1em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buttonloader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #f66d00;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #f66d00;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

.css-ekwfbp-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
  background-color: #f3f3f3 !important;
  opacity: 0.1 !important;
}
.join-button {
  padding: 24px 100px;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  background-color: #f66d00;
  cursor: pointer;
  margin: auto;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  top: 60px;
  color: red;
  cursor: pointer;
  background: #ffffff;;
}

.dropdown-content-profile {
  font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 21px;
color: #333333;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.error {
  border: 1px solid #EB5757 !important;
  background-color:  #FFE8E8 !important;
}

.copied {
  color: #219653;
}
/* 
.modal-background {
  background-color: none !important;
  opacity: 0.6;
} */

@media screen and (max-width: 600px) {
  .join-button {
    padding: 24px 50px;
  }
}